<script setup lang="ts">
import { useRouter } from 'vue-router';
import SubmissionPipeline from './SubmissionPipeline.vue';


// Variable Declarations
const router = useRouter();


// Function Declarations
const goToForm = (route: string) => {
    router
        .push({ name: route })
        .catch((err) => {
            console.log(err);
        });
};

</script>

<template>
    <div class="h-64 md:h-64 bg-green-500 flex items-center justify-center text-white font-pragmaticaextendedbold text-3xl md:text-4xl relative">
        <p>Science & Medicine</p>
        <div class="absolute bottom-0 right-0 px-6 py-2 font-pragmaticamedium md:text-2xl bg-green-800 rounded-tl-md cursor-pointer text-xl" @click="goToForm('guidelines')">
            Submit Here
        </div>
    </div>
    <div class="flex flex-col items-center mt-10 space-y-16">
        <div class="w-[85%]">
            <div class="text-xl sm:text-2xl font-pragmaticaextendedbold">
                About
            </div>
            <div class="text-balance indent-6 sm:w-1/2 text-md sm:text-lg pt-2">
                This journal is dedicated to highlighting the latest advancements and discoveries in the fields of biology, chemistry, physics, health sciences, and medicine. 
                Each journal edition will highlight groundbreaking and novel discoveries in the fields of science and medicine highlight the world class research conducted by undergraduate students.             
            </div>
        </div>
        <div class="w-[85%]">
            <SubmissionPipeline />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import Contact from './Contact.vue';

//Variable Declarations
const imageDeck = ref(["/images/Volume-1/Volume1-Science.png", "/images/Volume-1/Volume1-Arts.png"]);
const currentImage = ref(imageDeck.value[0]);
const pdfLink = ref(["/files/Volume1-Arts.pdf","files/Volume1-SciMed.pdf"]);
const currentIndex = ref(0); 
let intervalId : any;

onMounted(() => {
    intervalId = setInterval(rotateImages, 10000);
});

onUnmounted(() => {
    clearInterval(intervalId);
});

//Function Declarations
const rotateImages = () => {
    currentIndex.value = (currentIndex.value + 1) % imageDeck.value.length;
    currentImage.value = imageDeck.value[currentIndex.value];
}
</script>
<template>
    <div class="w-full h-full">
        <div class="h-64 md:h-64 bg-[#012331] flex items-center justify-center text-white font-pragmaticaextendedbold text-3xl md:text-4xl relative">
            <p>Current Issue</p>
        </div>
        <div class="h-full w-full flex flex-col items-center pt-10 pb-20">
            <div class="w-[80%] h-full flex justify-center">
                <div class="h-full w-fit flex justify-center items-center flex flex-col space-y-4 ">
                    <div class="image-container object-contain rounded-md shadow-lg border-4 border-[#012331]" :style="`background-image: url('${currentImage}')`"></div> 
                    <div class="w-full space-y-2">
                        <p class="font-pragmaticabold text-lg">
                            JUI Volume 1, Issue 1
                        </p>
                        <div class="flex space-x-4 underline font-pragmaticaregular text-blue-800">
                            <a :href="pdfLink[1]" target="_blank">
                                Science & Medicine
                            </a>
                            <a :href="pdfLink[0]" target="_blank">
                                Arts & Humanities
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center py-6 border-t-2 ">
            <div class="w-[90%] sm:w-[70%] ">
                <Contact />
            </div>
        </div>
    </div>
</template>

<style scoped>
.image-container {
    width: 34vh;
    height: 50vh;
    background-size: cover;
    background-position: center;
    transition: background 1s ease-in-out;
}

@media screen and (min-width: 768px) {
    .image-container {
        width: 44vh;
        height: 65vh;
    }
}
</style>
<script setup lang="ts">
import { useRouter } from 'vue-router';
import SubmissionPipeline from './SubmissionPipeline.vue';


// Variable Declarations
const router = useRouter();


// Function Declarations
const goToForm = (route: string) => {
    router
        .push({ name: route })
        .catch((err) => {
            console.log(err);
        });
};

</script>

<template>
    <div class="h-64 md:h-64 bg-purple-500 flex items-center justify-center text-white font-pragmaticaextendedbold text-3xl md:text-4xl relative">
        <p>Arts & Humanities</p>
        <div class="absolute bottom-0 right-0 px-6 py-2 font-pragmaticamedium md:text-2xl bg-purple-900 rounded-tl-md cursor-pointer text-xl" @click="goToForm('guidelines')">
            Submit Here
        </div>
    </div>
    <div class="flex flex-col items-center mt-10 space-y-16">
        <div class="w-[85%]">
            <div class="text-xl sm:text-2xl font-pragmaticaextendedbold">
                About
            </div>
            <div class="text-balance indent-6 sm:w-1/2 text-md sm:text-lg pt-2">
                This journal is dedicated to showcasing the creative and scholarly achievements of undergraduate students in the fields of arts and humanities. 
                Each edition will feature original work in literature, history, philosophy, visual arts, cultural studies, and other related disciplines. Our goal is to provide a spotlight for thought-provoking contributions of undergraduate students to foster a setting for diverse perspectives and ideas. 
           </div>
        </div>
        <div class="w-[85%]">
            <SubmissionPipeline />
        </div>
    </div>
</template>
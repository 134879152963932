<script setup lang="ts">
import { ref, toRefs } from 'vue';
import emailjs from 'emailjs-com';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

// Variable Declarations
const isDragOver = ref(false);
const selectedFile = ref([] as File[]);
const fileInput = ref<HTMLInputElement | null>(null);
const currentSectionsExpanded = ref([] as string[]);
const s3 = new S3Client({
  region: import.meta.env.VITE_AWS_S3_REGION,
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_PUBLIC_ACCESS_KEY,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  },
});

const form = ref({
    name: '',
    section: '',
    email: '',
    faculty: '',
    pdf: [] as File[],
});

// Function Declarations
const expandsection = (section: string) => {
    if (currentSectionsExpanded.value.includes(section)) {
        currentSectionsExpanded.value = currentSectionsExpanded.value.filter((value) => value !== section);
    } else {
        currentSectionsExpanded.value.push(section);
    }
};

async function emailForm(formValues: { name: any; email: any; faculty: any; section: any; pdf: (File | null)[]; }) {
  const { name, email, faculty, section, pdf } = formValues
  const fileUrls = [];

  if (pdf) {
    for (let i = 0; i < pdf.length; i++) {
        const file = pdf[i];
        if (file) {
            const pdfBase64 = await fileToBase64(file);
            const pdfBlob = base64ToBlob(pdfBase64, 'application/pdf');
            const uploadParams = {
                Bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
                Key: `${Date.now()}-${file.name}`,
                Body: pdfBlob,
                ContentType: 'application/pdf',
            };

            const uploadResult = await s3.send(new PutObjectCommand(uploadParams));
            const fileUrl = `https://${uploadParams.Bucket}.s3.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/${uploadParams.Key}`;
            fileUrls.push(fileUrl);
            }
        }
    }   

  const fileUrlsString = fileUrls.join('\n');
  
  emailjs.send(import.meta.env.VITE_EMAILJS_SERVICE_ID, import.meta.env.VITE_EMAILJS_SUBMISSIONS_TEMPLATE_ID, {
    to_name: 'faheemmazhar@gmail.com',
    from_name: 'theJUIsubmissionbot',
    message: `Name: ${name}\nEmail: ${email}\nFaculty: ${faculty}\nSection: ${section}\nFiles: ${fileUrlsString}`,
  }, import.meta.env.VITE_EMAILJS_USER_ID)
  .then((response) => {
     console.log('Successfully emailed form: ', response.status, response.text);
     if (response.status === 200) {
       form.value.name = '';
       form.value.email = '';
       form.value.faculty = '';
       form.value.section = '';
       form.value.pdf = [];
       selectedFile.value = [];
     }
  }, (err) => {
     console.error('Error emailing form: ', err);
  });
}

function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

function base64ToBlob(base64: any, mimeType: string) {
  const bytes = atob(base64.split(',')[1]);
  const array = new Uint8Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    array[i] = bytes.charCodeAt(i);
  }
  return new Blob([array], { type: mimeType });
}

const handleFileUpload = (event: { target: { files: any[]; }; }) => {
    form.value.pdf = event.target.files[0];
};

const submitForm = () => {
    // Handle form submission here
};

function onDragOver(event: DragEvent) {
    event.preventDefault();
    isDragOver.value = true;
}

function dragEnter(event: DragEvent) {
    event.preventDefault();
    isDragOver.value = true;
}

function dragLeave(event: DragEvent) {
    event.preventDefault();
    isDragOver.value = false;
}

function onDrop(event: DragEvent) {
    event.preventDefault();
    isDragOver.value = false;
    const files = event.dataTransfer?.files;
    if (files) handleFiles(files);
}

function onFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) handleFiles(files);
}

function handleFiles(files: FileList) {
    if (!form.value.pdf) {
        form.value.pdf = [];
    }
    for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'application/pdf') {
            if (selectedFile.value) {
                selectedFile.value.push(files[i]);
            }
            form.value.pdf.push(files[i]);
        } else {
            alert('Please upload a PDF file.');
        }
    }
}

function removeFile(index: number) {
    if (form.value.pdf) {
        selectedFile.value.splice(index, 1);
        form.value.pdf.splice(index, 1);
    }
}

function browseFiles() {
    fileInput.value?.click();
}
</script>
<template>
    <div class="pb-12">
        <div class="h-64 md:h-64 bg-[#20aab9] flex flex-col space-y-6 items-center justify-center text-white font-pragmaticaextendedbold text-2xl md:text-4xl relative text-center">
            <p>Author Guideliness</p>
            <p>The Journal of Undergraduate Inquiry</p>
        </div>
        <div class="w-full flex flex-col space-y-4 justify-center pt-8 px-4 font-pragmaticaregular lg:text-lg">
            <div class="w-[95%] flex flex-col space-y-2">
                <div id="Overview" class="flex space-x-2 font-pragmaticaextendedbold text-xl" @click="expandsection('Overview')">
                    <svg v-if="currentSectionsExpanded.includes('Overview')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <p class="cursor-pointer hover:underline text-[#012331]">General Overview</p>
                </div>
                <Transition name="slide-fade">
                    <div v-if="currentSectionsExpanded.includes('Overview')" class=" text-balance indent-6 flex justify-start pl-8 sm:pl-14">
                        <p class="sm:w-[75%]"> 
                            Our editorial team is composed of undergraduate students from numerous specialties who review manuscripts to ensure they adhere to guidelines regarding eligibility and formatting. Manuscripts that fit our guidelines will be approved for specialty-specific review by faculty members to assess the quality of work and the significance of the contribution to the field. This two-tiered review process ensures that published works meet the high standards of academic excellence and rigor. 
                            Our team is dedicated to supporting authors throughout the submission and review process, providing constructive feedback to help refine and enhance their work. We believe in the importance of mentorship and collaboration, and our faculty reviewers play a crucial role in guiding students to produce their best possible work. 
                            Ultimately, our goal is to create a supportive and dynamic environment where undergraduate students can develop their research skills, gain valuable experience in academic publishing, and contribute meaningfully to their academic communities.
                        </p>
                    </div>
                </Transition>
            </div>
            <div class="w-[95%] flex flex-col space-y-2">
                <div id="Submissions" class="flex space-x-2 font-pragmaticaextendedbold text-xl" @click="expandsection('Submissions')">
                    <svg v-if="currentSectionsExpanded.includes('Submissions')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <p class="cursor-pointer hover:underline text-[#012331]">Submission Eligibility</p>
                </div>
                <Transition name="slide-fade">
                    <div v-if="currentSectionsExpanded.includes('Submissions')" class="text-balance flex flex-col justify-start pl-8 sm:pl-14">
                        <p class="sm:w-[75%] indent-6 sm:indent-0"> 
                            We accept submissions throughout the year and will process manuscripts for publishing in periodic intervals. The eligibility, quality, and significance of a manuscript will be graded based upon the following requirements; 
                        </p>
                        <ol class=" space-y-4">
                            <li>
                                <p class="font-pragmaticamedium">Originality:</p>
                                <p class="pl-4">The manuscript must present original research, creative work, or scholarly insights that have not been previously published elsewhere. </p>
                            </li>
                            <li>
                                <p class="font-pragmaticamedium">Intellectual Rigor:</p> 
                                <p class="pl-4">
                                    The work must demonstrate thorough research, methodology, and logical argumentation. For creative submissions, there should be a clear articulation of purpose and technique.
                                </p>
                            </li>
                            <li>
                                <p class="font-pragmaticamedium">Significance:</p> 
                                <p class="pl-4">The manuscript should exhibit a valued and meaningful contribution to the desired field. Submitted work is expected to align with current fields of discussion and growth while introducing novel theories, concepts, or methodologies that push the boundaries of traditional thinking and open new avenues for exploration.</p>
                            </li>
                            <li>
                                <p class="font-pragmaticamedium">Mentorship and Academic Validation:</p>
                                <p class="pl-4">The manuscript must have either been supervised by a faculty member, or validated coursework from a university-run academic course graded by a faculty member.</p>  
                            </li>             
                        </ol>
                    </div>
                </Transition>
            </div>
            <div class="w-[95%] flex flex-col space-y-2">
                <div id="Sections" class="flex space-x-2 font-pragmaticaextendedbold text-xl" @click="expandsection('Sections')">
                    <svg v-if="currentSectionsExpanded.includes('Sections')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <p class="cursor-pointer hover:underline text-[#012331]">Journal Sections</p>
                </div>
                <Transition name="slide-fade">
                    <div v-if="currentSectionsExpanded.includes('Sections')" class="text-balance flex flex-col space-y-4 justify-start pl-8 sm:pl-14">
                        <div>
                            <p class="font-pragmaticamedium">
                                Science and Medicine-
                            </p>
                            <p class="indent-6">
                                This journal is dedicated to highlighting the latest advancements and discoveries in the fields of biology, chemistry, physics, health sciences, and medicine. Each journal edition will highlight groundbreaking and novel discoveries in the fields of science and medicine highlight the world class research conducted by undergraduate students.  
                            </p>
                        </div>
                        <div>
                            <p class="font-pragmaticamedium">
                                Arts and Humanities
                            </p>
                            <p class="indent-6">
                                This journal is dedicated to showcasing the creative and scholarly achievements of undergraduate students in the fields of arts and humanities. Each edition will feature original work in literature, history, philosophy, visual arts, cultural studies, and other related disciplines. Our goal is to provide a spotlight for thought-provoking contributions of undergraduate students to foster a setting for diverse perspectives and ideas.                         </p>
                        </div>
                    </div>
                </Transition>
            </div>
            <div class="w-[95%] flex flex-col space-y-2">
                <div id="Formatting" class="flex space-x-2 font-pragmaticaextendedbold text-xl" @click="expandsection('Formatting')">
                    <svg v-if="currentSectionsExpanded.includes('Formatting')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <p class="cursor-pointer hover:underline text-[#012331]">Submission Formatting</p>
                </div>
                <Transition name="slide-fade">
                    <div v-if="currentSectionsExpanded.includes('Formatting')" class="text-balance indent-6 flex flex-col justify-start pl-8 sm:pl-14">
                        <p class="sm:w-[75%]"> 
                            The manuscript must be submitted in a PDF file with 12 point Times New Roman font, with all citations in Vancouver citation formatting with in-text superscripts.
                            Any instances of copyright or plagiarism will be immediately rejected.  
                            All supplemental tables and data must be submitted in a separate Microsoft Excel or Google Sheets file.  
                            All figures and tables must contain figure titles and captions and must be referred to with the figure title.  
                            The names of all contributors, including authors and supervisors must be included at the top of the document. Affiliations of all authors must also be stated, including department and faculty.  
                            Submissions can be expected to be read by undergraduate students of all disciplines. As such, writing should avoid technical terms that may be unrecognizable to those outside a specific discipline.  
                            All research-based submissions must include the following sections: abstract, introduction, methods, results, discussion, conclusions, acknowledgements, references.    
                            While no strict template is provided, all non-research manuscripts must follow a logical flow of presentation which is conducive to the understanding of the reader.                   
                        </p>
                    </div>
                </Transition>
            </div>
            <div class="w-[95%] flex flex-col space-y-2">
                <div id="Review" class="flex space-x-2 font-pragmaticaextendedbold text-xl" @click="expandsection('Review')">
                    <svg v-if="currentSectionsExpanded.includes('Review')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <p class="cursor-pointer hover:underline text-[#012331]">Review Process</p>
                </div>
                <Transition name="slide-fade">
                    <div v-if="currentSectionsExpanded.includes('Review')" class="text-balance flex flex-col justify-start pl-8 sm:pl-14">
                        <p class="sm:w-[75%] indent-6"> 
                            Once a manuscript is submitted, an initial review team ensures that each manuscript fulfills the requirements outlined above. 
                            If a manuscript is accepted for revisions, the manuscript will undergo a thorough peer-review process where 2-3 undergraduate students of related and unrelated disciplines to the manuscript at hand will provide feedback. 
                            Once feedback is provided back to the author, the author will have two weeks to re-submit the manuscript for final review. From this point, the manuscript will either be selected for publication or rejected.                   
                        </p>
                        <p>
                            The JUI will publish copies of the Science and Medicine, and Arts and Humanities journals twice per annum. Authors are encouraged to resubmit rejected work following revisions, however, are limited to one submission per journal period.  
                        </p>
                    </div>
                </Transition>
            </div>
        </div>
        <div>
            <div class="flex items-center justify-center font-pragmaticaextendedbold text-2xl md:text-3xl pt-20">
                <p>Submission Form</p>
            </div>
            <div class="flex items-center justify-center font-pragmaticaregular">
                <div class="w-[90%] sm:w-1/2  flex justify-center">
                    <form @submit.prevent="emailForm(form)" class="w-full flex flex-col items-center space-y-2 shadow-lg rounded-md p-4">
                        <div class="h-12 w-full sm:w-[80%] border-2 border-gray-500 rounded-md flex items-center">
                            <label for="name" class="pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                            </label>
                            <input id="name" placeholder="  Full Name" class="border-l-2 m-2 border-gray-500 pl-2 w-full " v-model="form.name" type="text" required>
                        </div>
                        <div class="h-12 w-full sm:w-[80%] border-2 border-gray-500 rounded-md flex items-center">
                            <label for="email" class="pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                </svg>
                            </label>
                            <input id="email" placeholder="  Email Address" class="border-l-2 m-2 border-gray-500 pl-2 w-full " v-model="form.email" type="email" required>
                        </div>
                        <div class="h-12 w-full sm:w-[80%] border-2 border-gray-500 rounded-md flex items-center">
                            <label for="faculty" class="pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                                </svg>
                            </label>
                            <input id="faculty" placeholder="  Faculty" class="border-l-2 m-2 border-gray-500 pl-2 w-full" v-model="form.faculty" type="text" required>
                        </div>
                        <div class="h-12 w-full sm:w-[80%] border-2 border-gray-500 rounded-md flex items-center">
                            <label for="section" class="pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            </label>
                            <input id="section" placeholder="  Section" class="border-l-2 m-2 border-gray-500 pl-2 w-full " v-model="form.section" type="text" required>
                        </div>
                        <div class="w-full sm:w-[80%]">
                            <h3 class="text-lg font-semibold mt-4 text-gray-600">Upload your PDF:</h3>
                        </div>
                        <div class="w-full sm:w-[80%] border-2 border-gray-500 rounded-md flex items-center">
                            <div class="container mx-auto p-4">
                                <div 
                                    @dragover.prevent="onDragOver" 
                                    @drop.prevent="onDrop" 
                                    @dragenter.prevent="dragEnter" 
                                    @dragleave.prevent="dragLeave" 
                                    class="drag-area rounded p-6 text-center"
                                    :class="{ 'bg-blue-100': isDragOver }"
                                >
                                    <div class="icon text-3xl">
                                        <i class="fas fa-file-pdf"></i>
                                    </div>
                                    <div class="flex flex-col justify-center items-center space-y-2" @click="browseFiles">
                                        <p class="header mt-2 text-gray-600">
                                            Drag & Drop or <span class="button underline cursor-pointer" @click="browseFiles">browse</span>
                                        </p>
                                        <div v-if="!selectedFile">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-600">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                                            </svg> 
                                        </div>
                                        
                                    </div>
                                    <input type="file" ref="fileInput" hidden @change="onFileChange" accept="application/pdf" multiple />
                                    <div v-for="(file, index) in selectedFile" :key="index" class="flex flex-col justify-center items-center space-x-4">
                                        <p class="selected-file text-blue-500 mt-2">Selected file: {{ (file as File).name }}</p>
                                        <div class="flex items-center pt-1">
                                            <button @click="removeFile(index)" class="rounded-lg cursor-pointer hover:text-red-500 flex text-sm">
                                                <p>Remove</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-2">
                            <button type="submit" class="bg-[#e68c74] px-8 py-2 rounded-full text-white hover:-translate-y-1 hover:scale-105">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
input:focus {
  outline: none;
  box-shadow: none;
  width: 100%;
}


.drag-area {
  cursor: pointer;
}
.button:hover {
  color: blue;
}
</style>
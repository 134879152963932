<script setup lang="ts">

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import Contact from './Contact.vue';

// Variable Declarations
const router = useRouter();

// Function Declarations
const showSection = (section: string) => {
    router
        .push({ name: section })
        .catch((err) => {
            console.log(err);
        });
};

</script>

<template>
    <div class="w-full flex flex-col justify-center overflow-y-scroll">
        <div class="w-full h-[50rem] sm:h-[75rem] flex justify-center sm:items-center bg-gradient-to-b from-[#ffffff] to-[#e68c74]">
            <div class="w-full sm:w-[90%] h-full sm:h-48 flex flex-col justify-center items-center space-y-4 sm:space-y-6 text-[#012331]">
                <h1 class="font-cinzelbold text-2xl lg:text-4xl xl:text-5xl text-center">The Journal of Undergraduate Inquiry</h1>
                <h1 class="font-serif text-xl lg:text-3xl">where curiosity meets discovery.</h1>
            </div>
        </div>
        <div class="h-[40rem] sm:h-[60rem] bg-[#e68c74] flex justify-center text-white">
            <div class="w-full lg:w-[80%] flex space-x-8 items-center">
                <div class="w-full w-[80%] sm:w-[75%] flex flex-col sm:space-y-4 px-6">
                    <span class="text-xl sm:text-3xl lg:text-4xl font-pragmaticaextendedbold">Who we are </span>
                        <div class="indent-10 text-sm sm:text-lg lg:text-xl font-pragmaticaregular flex flex-col space-y-4 pt-2 sm:pt-0">
                            <div>
                                The Journal of Undergraduate Inquiry is a student-run academic journal aimed to allow undergraduate students to showcase their research, creative, and scholarly work. We aim to publish works that exhibit excellence in intellectual rigor, originality, and contribution to their respective fields, fostering a culture of academic curiosity and innovation.                            </div>
                            <div>
                                Our journal accepts submissions across a wide range of disciplines, encouraging interdisciplinary dialogue and collaboration. By providing a platform for undergraduate students to publish their work, we hope to inspire future research endeavors and academic pursuits. We are committed to maintaining a high standard of quality and integrity in all published works, ensuring that each piece is thoroughly reviewed and critiqued by peers and faculty members.
                            </div>
                            <div>
                                Through this inclusive approach, The Journal of Undergraduate Inquiry aims to highlight the diverse talents and perspectives of undergraduate students, contributing to a vibrant and dynamic academic community.
                            </div>
                        </div>
                </div>
                <div class="w-[25%] hidden sm:inline">
                    <img class="object-contain" src="/images/Team.png" alt="" />
                </div>
            </div>
        </div>
        <div class="h-[30rem] sm:h-[50rem] bg-gradient-to-b from-[#e68c74] to-[#012331] flex justify-center text-white">
            <div class="w-full lg:w-[80%] flex sm:space-x-24 items-center">
                <div class="">
                    <img class="h-48 sm:h-72" src="/images/Microscope.png" alt="" />
                </div>
                <div class="w-[60%] flex flex-col space-y-4">
                    <span class="text-xl sm:text-3xl lg:text-4xl font-pragmaticaextendedbold">Our Mission</span>
                    <div class="indent-10 text-sm sm:text-lg lg:text-xl font-pragmaticaregular">
                        At JUI, we're not just publishing papers; we're building the next generation of researchers, scientists, and artists. Our mission is to amplify undergraduate voices, turning groundbreaking ideas into impactful realities.
                    </div>
                </div>
            </div>
        </div>
        <div class="h-[40rem] bg-[#012331] flex justify-center text-white font-pragmaticamedium">
            <div class="w-full flex flex-col sm:justify-center items-center">
                <div class="w-full flex justify-center text-white text-xl sm:text-2xl lg:text-3xl font-pragmaticaextendedbold">
                    <p>Explore our Journal Sections</p>
                </div>
                <div class="h-full w-[85%] sm:w-[65%] grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center text-[#012331] py-6 px-4 sm:space-x-8 ">
                    <div class="w-full h-64 sm:h-80 bg-white shadow-lg rounded-lg flex flex-col items-center p-2 pt-4">
                        <div class="mb-4 text-xl">
                            Science & Medicine
                        </div>
                        <div class="w-[90%] h-full flex flex-col justify-between font-pragmaticaregular">
                            <p class="lg:pl-4">
                                Explore groundbreaking research and developments in biology, chemistry, and medical sciences that shape our understanding of life and health.
                            </p>
                            <div class="flex justify-center">
                                <button class="bg-[#e68c74] text-white rounded-full px-4 py-2 hover:-translate-y-1 hover:scale-105" @click="showSection('sciencemedicine')">Explore this Section</button>
                            </div>
                        </div>
                    </div>
                    <div class="w-full h-64 sm:h-80 bg-white shadow-lg rounded-lg flex flex-col items-center p-2 pt-4">
                        <div class="mb-4 text-xl">
                            Arts & Humanities
                        </div>
                        <div class="w-[90%] h-full flex flex-col justify-between font-pragmaticaregular">
                            <p class="lg:pl-4">
                                Discover diverse perspectives in fine arts, literature, and social sciences that challenge conventional thinking and inspire creativity.
                            </p>
                            <div class="flex justify-center">
                                <button class="bg-[#e68c74] text-white rounded-full px-4 py-2 hover:-translate-y-1 hover:scale-105" @click="showSection('artshumanities')">Explore this Section</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="h-[40rem] bg-gradient-to-b from-[#012331] to-[#ffffff] flex justify-center text-white font-pragmaticamedium">
            <div class="w-full lg:w-[80%] flex flex-col justify-center items-center">
                <div class="w-full flex justify-center text-2xl lg:text-3xl font-pragmaticabol">
                    <p>View our latest Articles</p>
                </div>
                <div class="h-[75%] flex space-x-6 justify-center pt-6">
                    <div class="h-full w-[30%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-48 w-full object-cover rounded-t-lg" src="../assets/images/StockArticle.jpg" alt="" />
                            </div>
                            <div class="flex justify-center">
                                <h1 class="text-2xl">Title of Article</h1>
                            </div>
                            <div class="flex justify-center pl-4">
                                <p class="font-pragmaticaregular text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed etiam, ut inchoavit, instituit; Quae cum dixisset, finem ille. </p>
                            </div>
                            <div class="absolute bottom-4 w-full font-pragmaticaregular">
                                <div class="flex justify-center">
                                    <button class="bg-[#e68c74] text-white rounded-lg p-2">Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-full w-[30%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-48 w-full object-cover rounded-t-lg" src="@/assets/images/StockArticle.jpg" alt="" />
                            </div>
                            <div class="flex justify-center">
                                <h1 class="text-2xl">Title of Article</h1>
                            </div>
                            <div class="flex justify-center pl-4">
                                <p class="font-pragmaticaregular text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed etiam, ut inchoavit, instituit; Quae cum dixisset, finem ille. </p>
                            </div>
                            <div class="absolute bottom-4 w-full font-pragmaticaregular">
                                <div class="flex justify-center">
                                    <button class="bg-[#e68c74] text-white rounded-lg p-2">Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-full w-[30%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-48 w-full object-cover rounded-t-lg" src="@/assets/images/StockArticle.jpg" alt="" />
                            </div>
                            <div class="flex justify-center">
                                <h1 class="text-2xl">Title of Article</h1>
                            </div>
                            <div class="flex justify-center pl-4">
                                <p class="font-pragmaticaregular text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed etiam, ut inchoavit, instituit; Quae cum dixisset, finem ille. </p>
                            </div>
                            <div class="absolute bottom-4 w-full font-pragmaticaregular">
                                <div class="flex justify-center">
                                    <button class="bg-[#e68c74] text-white rounded-lg p-2">Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="flex justify-center py-6 bg-white ">
            <div class="w-[90%] sm:w-[70%] ">
                <Contact />
            </div>
        </div>
    </div>
    
</template>
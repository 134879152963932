import { createRouter, createWebHistory } from 'vue-router'
import Home from './Home.vue'
import News from './components/News.vue'
import Guidelines from './components/Guidelines.vue'
import Contact from './components/Connect.vue'
import ScienceMedicine from './components/ScienceMedicine.vue'
import ArtsHumanities from './components/ArtsHumanities.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/guidelines',
    name: 'guidelines',
    component: Guidelines
  },
  {
    path: '/connect',
    name: 'connect',
    component: Contact
  }
  ,
  {
    path: '/sciencemedicine',
    name: 'sciencemedicine',
    component: ScienceMedicine
  },
  {
    path: '/artshumanities',
    name: 'artshumanities',
    component: ArtsHumanities
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
<script setup lang="ts">
import { root } from 'postcss';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

// Variable Declarations
const showSections = ref(false);
const router = useRouter();


// Function Declarations

const goToHome = () => {
    router.push('/');
};


</script>

<template>
    <div class="relative flex flex-col sm:flex-row justify-between w-full md:w-[90%] sm:mb-2 items-center sm:items-start">
        <div class="w-1/2 mr-4 flex justify-end sm:justify-start hidden sm:inline">
            <img class="h-16 cursor-pointer" src="/images/JUIBlank.png" alt="" @click="goToHome()" />
        </div>
        <div class="w-full h-1/2 flex justify-center sm:hidden bg-[#012331]">
            <img class="h-12 cursor-pointer" src="/images/JUIwhite.png" alt="" @click="goToHome()" />
        </div>
        <div class="w-full sm:w-2/3 md:w-2/5 flex space-x-2 items-center py-1 sm:py-0">
            <div class="sm:h-3/4 w-full text-[#012331] flex justify-between items-center text-xs">
                <div>
                    <router-link to="/" class="flex flex-col items-center rounded-lg transition px-2 pt-2 sm:pt-0 sm:pb-2 hover:bg-[#e68c74] sm:hover:-translate-y-1 sm:hover:scale-110 hover:text-white cursor-pointer">
                        <div class="p-1 hidden sm:inline ">Home</div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                        <div class="sm:hidden">Home</div>
                    </router-link>
                </div>
                <div>
                    <router-link to="/news" class="flex flex-col items-center rounded-lg transition px-2 pt-2 sm:pt-0 sm:pb-2 hover:bg-[#e68c74] sm:hover:-translate-y-1 sm:hover:scale-110 hover:text-white cursor-pointer">
                        <div class="p-1 hidden sm:inline">News</div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                        </svg>
                        <div class="sm:hidden">News</div>
                    </router-link>
                </div>
                <div class="relative" @mouseover="showSections = true" @mouseleave="showSections = false">
                    <div class="flex flex-col items-center rounded-lg transition px-2 pt-2 sm:pt-0 sm:pb-2 hover:bg-[#e68c74] sm:hover:-translate-y-1 sm:hover:scale-110 hover:text-white cursor-pointer text-ellipsis text-nowrap">
                        <div class="p-1 hidden sm:inline">Sections</div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <div class="sm:hidden">Sections</div>
                    </div>
                    <div v-if="showSections" class="absolute sm:-left-[8rem] -left-[4rem] w-[22rem] pt-2 z-10">
                        <div class="w-fit flex gap-x-6 p-2 justify-items-center text-[#012331] bg-white shadow-md rounded-xl text-nowrap">
                            <div class="w-fit bg-white rounded-lg flex items-center">
                                <router-link to="/sciencemedicine" class="flex flex-col items-center rounded-lg transition mb-2 hover:underline cursor-pointer">
                                    <div class="p-1 ">Science & Medicine</div>
                                </router-link>
                            </div>
                            <div class="w-fit bg-white rounded-lg flex flex-col items-center">
                                <router-link to="/artshumanities" class="flex flex-col items-center rounded-lg transition mb-2 hover:underline cursor-pointer">
                                    <div class="p-1 ">Arts & Humanities</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <router-link to="/guidelines" class="flex flex-col items-center rounded-lg transition px-2 pt-2 sm:pt-0 sm:pb-2 hover:bg-[#e68c74] sm:hover:-translate-y-1 sm:hover:scale-110 hover:text-white cursor-pointer">
                        <div class="p-1 hidden sm:inline ">Author Guidelines</div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                        </svg>
                        <div class="sm:hidden">Author Guidelines</div>
                    </router-link>
                </div>
                <div>
                    <router-link to="/connect" class="flex flex-col items-center rounded-lg transition px-2 pt-2 sm:pt-0 sm:pb-2 hover:bg-[#e68c74] sm:hover:-translate-y-1 sm:hover:scale-110 hover:text-white cursor-pointer">
                        <div class="p-1 hidden sm:inline ">Connect</div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                        </svg>
                        <div class="sm:hidden">Connect</div>
                    </router-link>
                </div>
            </div> 
        </div>
    </div>
</template>


<script setup lang="ts">

</script>
<template>
    <div class="sm:h-[40rem] flex justify-center">
            <div class="w-full flex flex-col justify-center items-center">
                <div class="h-full w-full xl:max-w-[82rem] flex space-y-4 sm:space-y-0 sm:space-x-6 justify-center pt-6 flex flex-col sm:flex-row">
                    <div class="h-[25rem] sm:h-[45rem] sm:w-[25%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-40 w-full object-cover rounded-t-lg" src="/images/Research.jpg" alt="" />
                            </div>
                            <div class="p-3 pb-6 flex flex-col space-y-2">
                                <h1 class="text-lg">Submit Your Research</h1>
                                <div class="flex justify-center">
                                    <p class="font-pragmaticaregular text-md">
                                        Start your submsision and get published in the Journal of Undergraduate Inquiry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-[25rem] sm:h-[45rem] sm:w-[25%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-40 w-full object-cover rounded-t-lg" src="/images/Guidelines.jpg" alt="" />
                            </div>
                            <div class="p-3 pb-6 flex flex-col space-y-2">
                                <h1 class="text-lg ">Adhering to Journal Standards</h1>
                                <div class="flex justify-center">
                                    <p class="font-pragmaticaregular text-md">
                                        Ready to Publish? Ensure your research meets the standards and required criteria for publication, read our author guidelines and learn more about the submission process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-[25rem] sm:h-[45rem] sm:w-[25%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-40 w-full object-cover rounded-t-lg" src="/images/ScholarlyDialogue.jpg" alt="" />
                            </div>
                            <div class="p-2 pb-6 flex flex-col space-y-2">
                                <h1 class="text-lg ">Advancing Scholarly Dialogue</h1>
                                <div class="flex justify-center">
                                    <p class="font-pragmaticaregular text-md">
                                        Publications in our journal aim to contribute significantly to the scholarly community by presenting novel research that can spark new inquiries or shift current perspectives. 
                                        Each accepted manuscript must clearly demonstrate how it builds upon and adds to the existing academic discourse in meaningful ways.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-[25rem] sm:h-[45rem] sm:w-[25%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-40 w-full object-cover rounded-t-lg" src="/images/Community.jpg" alt="" />
                            </div>
                            <div class="p-2 pb-6 flex flex-col space-y-2">
                                <h1 class="text-lg ">Community Engagment</h1>
                                <div class="flex justify-center">
                                    <p class="font-pragmaticaregular text-md">
                                        We encourage authors to engage with the academic community through their research. 
                                        Our platform not only publishes research but also promotes discussions and collaborations through symposia, webinars, and online forums where authors can connect with other researchers and enthusiasts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-[25rem] sm:h-[45rem] sm:w-[25%] bg-white rounded-lg shadow-md">
                        <div class="relative h-full text-black flex flex-col text-wrap space-y-4">
                            <div class="flex justify-center">
                                <img class="h-40 w-full object-cover rounded-t-lg" src="/images/Inclusion.jpg" alt="" />
                            </div>
                            <div class="p-2 pb-6 flex flex-col space-y-2">
                                <h1 class="text-lg">Access and Inclusion</h1>
                                <div class="flex justify-center">
                                    <p class="font-pragmaticaregular text-md">
                                        Our journal is committed to making research accessible to a diverse audience, including those outside the specific field of study. 
                                        We aim to bridge the gap between specialized research and general interest through clear, concise, and engaging presentations of complex ideas and findings.                                    
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<scipt setup lang="ts">

</scipt>
<template>
    <div>
        <div class="w-full h-1/2">
            <h1 class="text-3xl font-pragmaticaextendedbold">Connect with us</h1>
        </div>
        <div class="flex flex-col space-y-6 px-4 ">
            <p class="sm:w-[75%] pt-6 indent-10">
                We’re here to help and always eager to hear from you! Whether you have questions, feedback, or simply want to share your thoughts, don't hesitate to get in touch. Below you'll find all the ways you can connect with us at The Journal of Undergraduate Inquiry. Let's make academic communication more dynamic and accessible together!
            </p>
            <div class="flex flex-col sm:flex-row sm:space-x-2">
                <p>
                    Email: For inquiries, submissions, or support, email us at:
                </p>
                <a href="mailto:contact@thejui.ca" class="font-pragmaticabold underline">
                    contact@thejui.ca
                </a>
            </div>
            <p class="indent-10 sm:indent-0">
                Follow us on our social platforms to stay updated on the latest volumes, calls for papers, new research oppurtunties and the exciting happenings around our community. Join the conversation and be a part of our growing network!
            </p>
            <div class="flex space-x-4">
                <a href="https://www.facebook.com/ucalgary" target="_blank" class="font-pragmaticabold underline">
                    <img class="h-8" src="/images/SocialMedia/facebook.png" alt="" />                    
                </a>
                <a href="https://www.instagram.com/ucalgary/" target="_blank" class="font-pragmaticabold underline">
                    <img class="h-8" src="/images/SocialMedia/instagram.png" alt="" />
                </a>
                <a href="https://twitter.com/UCalgary" target="_blank" class="font-pragmaticabold underline">
                    <img class="h-8" src="/images/SocialMedia/twitter.png" alt="" />
                </a>
            </div>
            <p>
                We look forward to hearing from you!
            </p>
        </div>
    </div>
</template>
// import './assets/main.css'

// import { createApp } from 'vue'
// import App from './Home.vue'
// import './tailwind.css';


// createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './Home.vue'
import router from './router'
import './assets/main.css'
import './tailwind.css';

createApp(App)
  .use(router)
  .mount('#app')
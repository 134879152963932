<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch} from 'vue';
import { useRouter } from 'vue-router';

import NavBar from './components/NavBar.vue';
import Landing from './components/Landing.vue';
import News from './components/News.vue';
import Guidelines from './components/Guidelines.vue';
import Connect from './components/Connect.vue';
import ScienceMedicine from './components/ScienceMedicine.vue';
import ArtsHumanities from './components/ArtsHumanities.vue';
import HideMobile from './components/HideMobile.vue';

// Variable Declarations
const router = useRouter();
const currentpath = ref("home");
const isMobile = ref(false);


// Function Declarations
onMounted(() => {
  router.push('/');

});

watch(router.currentRoute, () => {
  currentpath.value = router.currentRoute.value.name as string;
});

</script>

<template>
  <div>
    <div>
      <div class="h-full w-full flex items-center justify-center sm:pt-4 border-b-[#012331] border-b-2">
        <NavBar />
      </div>
      <div v-if="currentpath == 'home'" class="flex justify-center">
        <Landing />
      </div>
      <div v-else-if="currentpath == 'news'">
        <News />
      </div>
      <div v-else-if="currentpath == 'sciencemedicine'">
        <ScienceMedicine />
      </div>
      <div v-else-if="currentpath == 'artshumanities'">
        <ArtsHumanities />
      </div>
      <div v-else-if="currentpath == 'guidelines'">
        <Guidelines />
      </div>
      <div v-else-if="currentpath == 'connect'" class="flex justify-center">
        <Connect />
      </div>
    </div>
  </div>
</template>

<style scoped>
@import './tailwind.css';
</style>
